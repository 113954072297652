.nav {
    height: 60px;
  width: 100%;
  background-color:  #292c35cc;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.5);
  position: sticky;
  top: 0;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: end;
  column-gap: 24px;
  z-index: 4;
  box-sizing: border-box;
  padding: 0 40px 0 40px;
}




.navlink {
    font-size: 1.5rem;
    text-decoration: none;
    color: black;
    font-family: inherit;
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  font-weight: 500;
  font-size: 20px;
  transition: 0.3s;
  height: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.navlink:hover {
    color: white;
    transform: scale(1.2);
    cursor: pointer;
}


.nav >.active {
    color: #ecb785;
    border-bottom: 4px solid#98ebad;
  }

.navlink:nth-child(3) {
    margin-right: 40px;
}



  
  
  @media (max-width: 800px) {
    .nav {
      justify-content: center;
      height: 44px;
    }
  
    
  }
  
  @media (max-width: 550px) {
    .nav {
      column-gap: 0;
      padding: 0;
      justify-content: space-evenly;
    }
    .navlink {
        font-size: 15px;
    }
  }

