.about {
  display: flex;
  justify-content: center;
  color: white;
}

.about-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 80%;
  background-color: rgba(0, 0, 0, 0.507);
  padding: 40px;

  border-bottom: 1px solid white;
}

.bio-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.bio-text {
  font-size: 1.3rem;
}

.bio-wrapper {
  width: 80%;
  background-color: #1f2d50b2;
  padding: 20px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.tech-wrapper{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}



.skills {
  width: 80%;
  position: relative;
  padding-inline: 2.5rem;
  display: flex;
  justify-content: center;
}

.skills-carousel {
  overflow: hidden;
  width: 100%;
  margin-bottom: 30px;
}
.skills-carousel__container {
  display: flex;
  user-select: none;
  margin-left: -10px;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.skill {
  flex: 0 0 6rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-right: 1rem;
  position: relative;

  gap: 0.7rem;
  padding: 0.8rem 0.6rem;
  width: 5rem;
  background-color: inherit;
  border: dotted 1px;
  border-color: inherit;

  p {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    font-size: 0.7rem;
    text-align: center;
    text-transform: uppercase;
  }

  i {
    font-size: 4.5rem;
    text-align: center;
  }
}

.skills-carousel__prev,
.skills-carousel__next {
  cursor: pointer;
  width: 2rem;
  height: 2rem;

  color: inherit;
  position: absolute;
  left: 0rem;
  top: 50%;
  z-index: 2;
  background-color: transparent;
  font-size: 1rem;
  transform: translateY(-50%);
  border-radius: 50%;
  padding-left: 0.1rem;
  border: none;
  border-color: inherit;

  svg {
    width: 50%;
    height: 50%;
    color: inherit;
  }
}
.skills-carousel__next {
  right: 0rem;
  left: auto;
  padding-right: 0;
}

.skills-title {
  font-size: 1.5rem;
}

@media (max-width: 2100px) {
  .about-wrapper {
    width: 90%;
  }

  .bio-wrapper {
    width: 95%;
  }
}
@media (max-width: 800px) {
  .skill > i {
    font-size: 3rem;
  }
  .skills-carousel {
    width: 80%;
  }

  .skills {
    padding-inline: 0;
    width: 100%;
  }

  .tech-wrapper {
    width: 100vw;
  }
  .bio-wrapper {
    width: 95%;
    padding: 10px;
  }
  .about-wrapper {
    padding: 15px;
  }

  .bio-text {
    font-size: 1rem;
  }
}
