.projects {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  overflow-x: hidden;
}

.projects-wrapper {
  width: 80%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.507);
  padding: 20px;
  
  border-bottom: 0.5px solid white;
}
.projects-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}


.project {
  display: flex;
  width: 80%;
  justify-content: space-between;
  align-items:stretch;
  box-sizing: border-box;
  gap: 15px;
  margin-bottom: 50px;
  background-color: rgba(255, 255, 255, 0);
  padding: 15px;
  
  
}


.project-wrapper {
    display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.project-info {
  display: flex;
  
  flex-direction: column;
  justify-content:space-around;
  align-items: center;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  z-index: 2;
  width: 50%;
  min-height: 100%;
  background-color: #1f2d50b2;
}

.project-title {
    font-size: 1.8rem;
    font-weight: bolder;
    color: #e2b080
}

.project-description {
    width: 80%;
    font-size: 1.2rem;
}

.project-tech {
  width: 80%;
  color: #98ebad ;
}

.project-buttons {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: stretch;
    margin-top: 5px;
    margin-bottom: 5px;
}

.carousel-wrapper {
    width: 100%;
}


.source-button
{
  
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  color: #0a1858;
  background-color:  #98ebad;
  
  text-decoration: none;
  letter-spacing: .03rem;
  font-size: 1.2rem;
  margin-bottom: 7px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 15px;
  transition: 0.5s ease-in-out;
  
}



.live-button{
  
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  color: #0a1858;
  background-color: #d4a375;
  letter-spacing: .03rem;
  text-decoration: none;
  
  font-size: 1.2rem;
  margin-bottom: 7px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 15px;
  transition: 0.5s ease-in-out;
  
}

.source-button:hover,
.live-button:hover{
  filter: brightness(65%);
  
}

#reversed > .project {
    flex-direction: row-reverse;
}




@media (max-width:2100px) {
    .project {
        width: 95%;
        align-items: stretch;
    }

    .projects-wrapper {
        width: 90%;
    }
}

@media (max-width:1800px) {
  .project-description {
    width: 90%;
    font-size: 1rem;
}
}

@media (max-width:1600px) {
  .source-button,
.live-button {
  
  padding: 3px;
  font-size: 1.3rem;
}
.project-title {
  font-size: 1.5rem;
  
}
}

@media (max-width:1460px) {
  .project {
    flex-direction: column;
}
#reversed > .project {
  flex-direction: column;
}

.project-info {
  width: 100%;
}
}

@media (max-width:1460px) {
  .project-title {
    font-size: 1rem;
  }
}
@media (max-width:800px) {
  .project-description {
    width: 100%;
  }
  .project-tech {
    width: 100%;
  }
  .projects-wrapper {
    padding: 0 ;
  }
}
