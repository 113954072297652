.contact {
    display: flex;
    justify-content: center;
    color: rgb(255, 255, 255);
 
 }
 
 .contact-wrapper {
     
     display: flex;
     justify-content:flex-start;
     flex-direction: column;
     align-items: center;
     width: 80%;
     height: 100%;
     background-color: rgba(0, 0, 0, 0.507);
     padding: 20px;
 }
 .contact-paragraph {
   font-size: 1.5rem;
   margin-bottom: 30px;
 }

 .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:20px;
    width: 100%;
    margin-bottom: 30px;
 }

 .form > input {
    width: 50%;
    font-size: 1.8rem;
 }
 .form > textarea {
    width: 50%;
    font-size: 1.8rem;
 }

 .contact-title {
    font-size: 2.5rem;
   margin-bottom: 30px;
   font-weight: bold;
  
  }
 
  @media (max-width:2100px) {
  

   .contact-wrapper {
       width: 90%;
   }
 
 }

 .form > button {
   background-color: white;
  
  text-decoration: none;
  color: black;
  font-size: 1.5rem;
  margin-bottom: 7px;
  padding: 5px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 7px;
 }


@media (max-width:700px) {
   .contact-paragraph {
      font-size: 1rem;
      margin: 25px;
      
    }
    .form > input {
      width: 80%;
      font-size: 1.2rem;
   }
   .form > textarea {
      width: 80%;
      font-size: 1.2rem;
   }
}
