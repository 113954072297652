.hero {
    height:100vh;
    width: 100vw;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    
}

.hero-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: perspective(1000px) translateZ(100px);
    background-color: rgba(0, 0, 0, 0.493);
    padding: 20px;
    border-radius: 60px;
    box-shadow: 0 90px 40px -20px rgba(0, 0, 0, 0.534);
    
}

.navlink-hero {
    font-size: 2rem;
}

.hero-title {
    margin: 0;
    font-size: 2.8rem;
    color: rgb(255, 255, 255);
}
.hero-button {
    margin-top: 1rem;
    display: inline-block;
    padding: .55rem 1rem;
    border-radius: 10rem;
    color: rgb(0, 0, 0);
    border-style: none;
    
    font-size: 1.4rem;
    letter-spacing: .1rem;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #d4a375;
      border-radius: 10rem;
      z-index: -2;
    }
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color:#98ebad;
      transition: all .3s;
      border-radius: 10rem;
      z-index: -1;
    }
    &:hover {
      color: rgb(0, 0, 0);
      &:before {
        width: 100%;
      }
    }
  }

@media (max-width:700px) {
    .hero-title {
        
        font-size: 1.6rem;
        
    }
    .navlink-hero {
        font-size: 1.3rem;
    }

    .hero-button {
        font-size: 1rem;
    }
    
}
