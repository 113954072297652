
*,
*:after,
*:before {
    box-sizing: border-box;
    
}

html {
    min-height: 100vh;
    scrollbar-width: none;
    scroll-behavior: smooth;
    
}

body {
    margin: 0;
    padding: 0;
    line-height: 1.5;
    background: linear-gradient(rgba(0, 0, 0, 0.103), rgba(0, 0, 0, 0.103)), url('./images/63026273929f8.jpg');
    background-position: center;
    font-family: "Raleway", sans-serif;
    
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.2;
}


p {
    line-height: 1.5;
}

button{
    background-color: #e3874f;
}

section {
    width: 100%;
    height: 100%;
    /*will-change: transform;*/
    
}

.portfolio-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    color:white;
    width:100%;
    padding-top: 10px;
    padding-bottom: 5px;
    flex-direction: column;
    background-color:  #292c35cc;
    
}

.portfolio-footer > a {
    font-size: 0.8rem;
    margin-bottom: 0;
    color: white;
    text-decoration: underline;
    padding: 0
}

